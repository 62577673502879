import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Homepage: React.FC = () => (
  <Layout>
    <SEO title="Contact" />
    <h3 className="text-center">Contact VoiceForward.org</h3>

    <p>For general questions or concerns, contact us at support@voiceforward.org</p>
    <p>To report abuse, contact us at abuse@voiceforward.org</p>
  </Layout>
)

export default Homepage
